.price-table-conatiner {
  display: flex;
  flex-direction: column;
  align-items: center;

  table {
    width: 450px;

    td {
      width: 150px;
      max-width: 150px;
      min-width: 150px;
      color: #000;
      font-weight: 500;
    }
  }

  .live-price-tab {
    padding: 2px 8px 4px 8px;
    border: none;
    border-radius: 4px;

    &[aria-selected='true'] {
      background-color: #013640;
      color: #ffffff;
    }
  }

  @media screen and (max-width: 480px) {
    #myTabContent {
      max-width: 300px;
      overflow-x: auto;
    }
  }
}
