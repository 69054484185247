.thank-you-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-top: 100px;
  color: #ffffff;

  h1 {
    font-size: 40px;
  }

  p {
    font-size: 20px;
  }

  img {
    margin-top: 40px;
    width: 100%;
    max-width: 600px;
  }
}