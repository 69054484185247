body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans',
    'Droid Sans', 'Helvetica Neue', sans-serif;
}

.desktop {
  @media (max-width: 641px) {
    display: none;
  }
}

@tailwind base;
@tailwind components;
@tailwind utilities;
