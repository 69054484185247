.clients-container {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  column-gap: 20px;
  row-gap: 20px;

  .client-container {
    width: 100%;
    height: 100%;
    display: flex;
    padding: 20px;
    background: #f5f5f5;
    border-radius: 8px;
    text-align: center;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    .client-img {
      width: 100%;
      max-width: 200px;
      margin-bottom: 8px;
    }
  }
}
